var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"equipment-card__header-right-controls"},[_c('x-form',{style:({ 'grid-auto-flow': 'column' }),attrs:{"field-attrs":_vm.allFieldAttrs,"submit-button-state":_vm.$v.$invalid || _vm.invalidRate,"submit-button-loading":_vm.useSubmitButtonLoading,"submit-error-text":_vm.submitError,"submit-button-outlined":true,"submit-button-text":"Передать показания"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}},scopedSlots:_vm._u([{key:"textField",fn:function({ attrs, fieldName }){return [_c('span',[_c('v-text-field',{staticClass:"form__input_size_sm pr-1",attrs:{"error-messages":_vm.getErrors(fieldName),"type":attrs.type,"rules":[(v) => (v && _vm.validateRate(v)) || 'Неправильный формат'],"hide-details":"","required":attrs.required,"outlined":_vm.fieldProps.outlined,"single-line":_vm.fieldProps.singleLine,"suffix":"ед.","placeholder":_vm.getMask('0'),"disabled":_vm.valuesInputDisabled[_vm.counterId]},on:{"input":function($event){return _vm.$v.values[fieldName].$touch()},"blur":function($event){return _vm.$v.values[fieldName].$touch()}},model:{value:(_vm.values[fieldName]),callback:function ($$v) {_vm.$set(_vm.values, fieldName, $$v)},expression:"values[fieldName]"}})],1),_c('span',{staticClass:"secondary--text text--lighten-1 d-flex text-body-2 pt-1"},[_c('span',[_vm._v(" Предыдущее показание")]),_c('span',{staticClass:"ml-auto"},[_vm._v(_vm._s(_vm.indication))])])]}},{key:"beforeSubmitButton",fn:function(){return undefined},proxy:true},{key:"afterSubmitButton",fn:function(){return undefined},proxy:true}])}),_c('confirm-action-dialog',{attrs:{"show-dialog":_vm.show,"metering-point-id":_vm.meteringPointId,"counter-id":_vm.counterId,"tarif":_vm.tarif,"indication":parseFloat(_vm.values.indication.replace(/,/, '.'))},on:{"agree":() => {
        _vm.$children[0].resetFormRefs();
        _vm.closeAllDialogs();
        _vm.showSuccessInfo();
        _vm.resetData();
      },"error":() => {
        _vm.$children[0].resetFormRefs();
        _vm.closeAllDialogs();
        _vm.resetData();
      },"disagree":() => {
      _vm.closeAllDialogs();
    }}}),_c('x-notice',{attrs:{"show":_vm.useSuccessAlert}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }