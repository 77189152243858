
  import {Component, Prop, Vue} from "vue-property-decorator";
  import { AccountingPointPU } from "@/models/accounting-point";
  import SimpleCard from "../SimpleCard.vue";
  import NewValueForm from "./CountersNewValueForm.vue";
  import ContractIcon from "@/components/ContractIcon.vue";
  import SimpleCardWithList from "@/components/SimpleCardWithList.vue";
  import {mapGetters} from "vuex";

  @Component({
    components: {
      SimpleCard,
      ContractIcon,
      NewValueForm,
      SimpleCardWithList
    },
    computed: {
      ...mapGetters({
        counterSendingPeriod: "accountingPoints/counterSendingPeriod",
        valuesInputDisabled: "accountingPoints/valuesInputDisabledForCounters"
      }),
    },
  })
  class EquipmentListCard extends Vue {
    @Prop({required: true}) readonly equipment!: AccountingPointPU;

    get counterData() {
      const {типсчетчика, заводскойномер, услуга, тип, тарифность, коэфтр, тариф, зонатарифа} = this.equipment;

      return [
        {title: 'марка', value: типсчетчика},
        {title: 'заводской номер', value: заводскойномер},
        {title: 'услуга', value: услуга},
        {title: 'тип', value: тип},
        {title: 'тарифность', value: тарифность},
        {title: 'коэф.тр', value: коэфтр},
        {title: 'тариф/зона', value: зонатарифа},
      ]
    }
  }

  export default EquipmentListCard;
