
import { Component, Emit, Prop, Watch, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { formatDate, getMonthName, getDateByString } from "@/lib/date";
import XButton from "../SimpleButton.vue";
import { datePickerForm } from "@/assets/scripts/form-fields/main";

const { values, attrs, validations, errors } = datePickerForm;

@Component({
  components: { XButton },
  mixins: [validationMixin],
  computed: errors,
  validations,
})
class DropdownWithDatePicker extends Vue {
  [x: string]: any;

  @Prop({ default: "" }) readonly modClass!: string;
  @Prop({ default: true }) readonly showMdiChevronDown!: boolean;
  @Prop({ default: false }) readonly setDefaultDate!: boolean;

  values = values;
  allFieldAttrs = attrs;

  activatorText = "";
  dates: string[] = [];
  currentDateIndex = 0;

  public created() {
    this.defaultStateHook();
    this.emitPickEvt();
  }

  @Watch("setDefaultDate")
  public setDefaultDateChanged() {
    if (this.setDefaultDate) {
      this.resetDateChanges();
    }
  }

  @Emit()
  private emitPickEvt() {
    this.$emit(
      "update:dates",
      this.dates.map((date) => new Date(date).toISOString().slice(0, 19))
    );
  }

  @Emit("click")
  public propagateCustomEvt(evtName: string) {
    this.$emit(evtName, evtName);
  }

  private defaultStateHook() {
    // для даты "от" необходимо устанавливать первое число, поскольку
    // иначе будет выбрано текущее число стартового месяца, а если стартовый
    // месяц февраль, а текущее число больше чем число дней в феврале в текущем году,
    // то стартовый месяц станет мартом, а его число сдвинется на кол-во дней,
    // на которое число текущего месяца превышает общее кол-во дней в феврале текущего года
    const startDate = new Date((new Date()).setDate(1));
    const endDate = new Date();

    // Использовать значение 5, поскольку индексация месяцев начинается с 0,
    // что позволяет установить дате значение, которое отличается от текущего
    // на полгода.
    startDate.setMonth(startDate.getMonth() - 5);

    const humanDates = [startDate, endDate].map((date) =>
      formatDate(date, "monthAndYear")
    );
    const isoDates = [startDate, endDate].map((date) =>
      date.toISOString().slice(0, 7)
    );

    this.values.startDate = humanDates[0];
    this.values.endDate = humanDates[1];
    this.dates = isoDates;

    this.activatorText = humanDates.join(" - ");
  }

  public formatMonth(date: string): string {
    return getMonthName(date, true).toUpperCase();
  }

  public onMonthClick(date: string) {
    const formattedDate = formatDate(date, "monthAndYear");

    if (this.currentDateIndex) {
      this.values.endDate = formattedDate;
      this.currentDateIndex--;
    } else {
      this.values.startDate = formattedDate;
      this.currentDateIndex++;
    }
  }

  public applyDateChanges() {
    const { startDate, endDate } = this.values;

    const isoDates = [startDate, endDate].map((date) => getDateByString(date));
    const parsedDates = isoDates.map((date) => Date.parse(date.toString()));

    if (parsedDates[0] > parsedDates[1]) {
      this.dates = isoDates.reverse();
      this.values.startDate = endDate;
      this.values.endDate = startDate;
    } else {
      this.dates = isoDates;
    }

    this.currentDateIndex = 0;
    this.activatorText = Object.values(this.values).join(" - ");

    this.emitPickEvt();
  }

  public resetDateChanges() {
    this.defaultStateHook();
    this.$v.$reset();
    this.currentDateIndex = 0;
  }

  public getErrors(fieldName: string): string | string[] {
    return this[`${fieldName}Errors`];
  }
}

export default DropdownWithDatePicker;
