
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import XAlert from "../hoc/Alert.vue";

@Component({
  components: { XAlert },
  computed: {
    ...mapState({
      appEnviroment: "appEnviroment",
      error: "error",
      preloader: "preloader",
    }),
    ...mapGetters("error", { currentError: "currentMessage" }),
  },
})
class Page extends Vue {
  [x: string]: any;

  @Prop({ default: "" }) readonly contentModClass!: string;
  @Prop({ default: true }) readonly useHeader!: boolean;

  public get useValuesInput(): boolean {
    const { appEnviroment } = this;

    return !!(appEnviroment.constants && appEnviroment.constants["ЛКЮЛПОКАЗ"]);
  }

  public get titles(): { [routeName: string]: string } {
    return {
      accountSettings: "Настройки аккаунта",
      companyCard: "Карточка организации",
      contractCard: "Карточка договора",
      countersValues: this.useValuesInput ? "Показания" : "История показаний",
      documents: "Документы",
      hourlyConsumption: "Почасовое потребление",
      monthlyPlan: "Помесячный план",
      myContracts: "Мои договоры",
      notifications: "Уведомления",
      payments: "Оплата",
      turnoversAndReports: "Обороты и отчеты",
      appealsByDogovor: "Обращения",
      appealChat: "",
    };
  }

  public get title(): string {
    const routeName = this.$route.name || "";

    if (!routeName) {
      return "";
    }

    const routeNameAsRegExp = new RegExp(`^${routeName}$`, "i");
    const titleKey =
      Object.keys(this.titles).find((key) => routeNameAsRegExp.test(key)) || "";

    return titleKey && this.titles[titleKey];
  }


}

export default Page;
