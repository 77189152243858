
import {Component, Mixins} from "vue-property-decorator";
import XDropdown from "@/components/SimpleDropdown.vue";
import {mapGetters, mapMutations} from "vuex";
import AppApiMixin from "@/components/mixins/AppApi.vue";

@Component({
  components: { XDropdown },
  computed:{
    ...mapGetters({ asyncJobs: "reports_downloads/getAsyncJobs" }),
    ...mapGetters("contract", { contractId: "id" })
  },
  methods:{
    ...mapMutations(
        "reports_downloads", {
          setAsyncJobs: "setAsyncJobs"
        }
    )
  },
})

class DownloadsDropdown extends Mixins(AppApiMixin, XDropdown) {
  [x: string]: any;
  async stopJob(jobId){
    const response = await this.stopAsyncJob(jobId, this.contractId)

    let jobs = this.asyncJobs

    if(response.status == 200){
      jobs = jobs.filter(el => el.asyncId != jobId)

      this.setAsyncJobs(jobs)
    }
  }
}
export default DownloadsDropdown
