
import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import EquipmentListCard from "@/components/for-counters-values/EquipmentListCard.vue";

@Component({
  components: {
    EquipmentListCard,
  },
  computed: {
    ...mapState({
      accountingPointsPU: "accountingPoints",
    }),
    ...mapGetters({
      contractId: "contract/id",
    }),
  },
})
class ValuesInput extends Mixins(AppApiMixin) {
  [x: string]: any;

  created() {
    this.fetchAccountingPointsPU(this.contractId);
  }
}

export default ValuesInput;
