
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

interface TableHeader {
  text: string;
  value: string;
  align?: string;
  class?: string;
  sortable?: boolean;
  width?: number | string;
}

@Component
class DataTable extends Vue {
  @Prop({ required: true }) readonly headers!: TableHeader[];
  @Prop({ required: true }) readonly items!: Array<[]>;

  @Prop({ default: "" }) readonly caption!: string;
  @Prop({ default: false }) readonly useCaptionAsIndex!: boolean;
  @Prop({ default: "" }) readonly itemClass!: string;
  @Prop({ default: false }) readonly highlightLastRow!: boolean;

  @Prop({ default: false }) readonly useItemSlot!: boolean;
  @Prop({ default: false }) readonly useHeaderCheckbox!: boolean;

  @Prop({ default: false }) readonly checkbox!: boolean;

  // Свойства, использующиеся для контроля и управления пагинацией.
  paginationProps = {
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  };

  public get showTop(): boolean {
    return !!(this.caption || this.$slots.afterCaption);
  }

  public get preparedCaption(): string {
    if (this.useCaptionAsIndex) {
      return ~parseInt(this.caption)
        ? `Объект ${this.caption}`
        : "Без привязки";
    }
    return this.caption;
  }

  public get preparedHeaders(): any[] {
    return this.headers.map((header, i) => {
      const { class: className = "", sortable = false } = header;

      const headerClassName = [
        "font-weight-medium",
        "px-2",
        "secondary--text",
        "text-body-1",
        "text--lighten-3",
        className,
        !i ? "pl-4" : "",
        i === this.headers.length - 1 ? "pr-4" : "",
      ]
        .join(" ")
        .trimEnd();

      return { ...header, class: headerClassName, sortable };
    });
  }

  public get preparedItems(): any[] {
    let strings: string[] = [];
    const columnIds = this.headers.reduce(
      (acc, { value: columnId }) => [...acc, columnId],
      strings
    );
    return this.items.map((item) => {
      return item.reduce(
        (acc, columnData, i) => ({ ...acc, [columnIds[i]]: columnData }),
        []
      );

    });
  }

  public get currentItemsRangeAsText(): string {
    const itemsLength = this.items.length;

    // Элементы таблицы отсутствуют.
    if (!itemsLength) {
      return "Записей не найдено";
    }

    const currentPage = this.paginationProps.page;
    const itemsPerPage = this.paginationProps.itemsPerPage;

    let start;
    let stop;

    // Получить значения для текста пагинации на 1-ой странице.
    if (currentPage === 1) {
      const showAllItems = !~itemsPerPage || itemsPerPage > itemsLength;

      start = currentPage;
      stop = showAllItems ? itemsLength : itemsPerPage;
    }
    // Получить значения для текста пагинации на остальных страницах.
    else {
      start = (currentPage - 1) * itemsPerPage + 1;
      stop = itemsPerPage * currentPage;

      stop = stop > itemsLength ? itemsLength : stop;
    }

    return `Записей ${start}-${stop} из ${itemsLength}`;
  }

  public get preparedItemsCssClass(): string {
    const { highlightLastRow, paginationProps, itemClass } = this;
    const { page, pageCount } = paginationProps;

    return [
      "data-table__text",
      "text-body-2",
      "text-lg-body-1",
      itemClass,
      highlightLastRow && page === pageCount ? "data-table__text_type_td" : "",
    ]
      .join(" ")
      .trim();
  }

  public created() {
    this.emitPaginationEvt();
  }

  @Watch("paginationProps")
  public paginationChanged() {
    this.emitPaginationEvt();
  }

  @Emit()
  private emitPaginationEvt() {
    this.$emit("update:paginationProps", this.paginationProps);
  }
}

export default DataTable;
