
import {Component, Mixins, Vue, Watch} from "vue-property-decorator";
import {mapGetters, mapMutations, mapState} from "vuex";
import AppBar from "@/components/for-page-grid/AppBar.vue";
import Sidebar from "@/components/for-page-grid/Sidebar.vue";
import appApi from "@/assets/scripts/app-api";
import DocGetterMixin from "@/components/mixins/DocGetter.vue";

@Component({
  components: { AppBar, Sidebar },
  computed: {
    ...mapState({ auth: "auth", dialog: "dialog", asyncJobs: "reports_downloads" }),
    ...mapGetters("contract", { contractId: "id" })
  },
  methods:{
    ...mapMutations(
        "reports_downloads", {
          setAsyncJobs: "setAsyncJobs"
        }
    )
  },
})
class App extends Mixins(Vue, DocGetterMixin){
  [x: string]: any;

  mounted(){
    this.checkAsyncJobs()
  }

  /*
    Использовать свойство topCssProp при работе со всплывающими элементами страницы,
    такими как, поупап или слайдер, с целью контроля текущих координат пользователя
    по оси Y, что позволяет "сохранить" позицию пользователя при закртыии указанных
    элементов
  */
  topCssProp = { value: 0, valuePx: "" };

  public get loggedIn(): boolean {
    return this.auth.loggedIn;
  }

  public get isOpenDialog(): boolean {
    return this.dialog.isOpen;
  }

  public get hasAsyncJobs(): boolean {
    return this.asyncJobs.asyncJobs.length
  }

  @Watch("loggedIn")
  public loggedInChanged() {
    if (!this.loggedIn) {
      this.$router.push({ name: "auth" });
    }
  }

  @Watch("isOpenDialog")
  public isOpenDialogChanged() {
    if (this.dialog.isOpen) {
      const top = window.pageYOffset;

      this.topCssProp.value = top;
      this.topCssProp.valuePx = top ? `-${top}px` : "";
    }
  }

  @Watch("hasAsyncJobs")
  public checkAsyncJobs(){
    let intervalId = setInterval(async ()=>{
      let jobs = this.asyncJobs.asyncJobs
      if(jobs.length > 0){
        const asyncIds = jobs.map(el => el.asyncId)

        try{
          let response = await appApi.checkAsyncJobStatus(asyncIds, this.contractId)
          response = response.data.data

          let jobsInProcess = []
          let downloadFiles = jobs.map(job => {
            if(response[job.asyncId].hasOwnProperty('result')){
              let file = response[job.asyncId].result
              file.fileName = job.name
              return file
            } else jobsInProcess.push(job)
          })

          for (const file of downloadFiles) {
            if(!!file){
              let fileData = await appApi.downloadReport(file, this.contractId, true)
              this.downloadDocument(fileData, file.fileName)
            }
          }

          this.setAsyncJobs(jobsInProcess)
        } catch(e) {
          this.setAsyncJobs([])
        }
      } else clearInterval(intervalId)
    }, 5000)
  }

  updated() {
    const top = this.topCssProp.value;
    window.scroll({ top });
  }
}

export default App;
