
import {AxiosResponse} from "axios";
import {Component, Vue} from "vue-property-decorator";

@Component
class DocGetter extends Vue {
  $refs!: {
    anchor: HTMLAnchorElement;
  };

  public getDocName(contentDisposition: string): string {
    const dispositionWithDocName = contentDisposition
      .split(";")
      .find((disposition) => disposition.includes("filename"));

    let docName = "some-doc";

    if (dispositionWithDocName) {
      const matched = dispositionWithDocName
        .trim()
        .replace(/["']/g, "")
        .match(/[ a-z\d]+\.[a-z\d]+$/i);

      docName = matched ? matched[0] : docName;
    }

    return docName;
  }

  private static createObjectURL(object) {
    const blob = new Blob([object], {type: object.type})
    return (window.URL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
    // return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
  }

  public downloadDocument(response: AxiosResponse, fileName?: string): void {

    const contentDisposition: string = response.headers["content-disposition"];

    const { data } = response;

    const link = document.createElement('a');
    link.href = DocGetter.createObjectURL(data);
    // link.href = (JSON.parse(response.config["data"]))["fileUrl"];

    let linkDownload = this.getDocName(contentDisposition);
    if(!!fileName){
      linkDownload = `${fileName}.${linkDownload.split(".")[1]}`
    }

    link.download = linkDownload
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
}

export default DocGetter;
