
import { Component, Mixins } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Page from "@/components/for-page-grid/Page.vue";
import XButton from "@/components/SimpleButton.vue";
import WelcomeCard from "@/components/for-my-contracts/WelcomeCard.vue";
import ContractTable from "@/components/for-my-contracts/ContractTable.vue";
import XSlider from "@/components/SimpleSlider.vue";
import ContractAddingForm from "@/components/for-my-contracts/ContractAddingForm.vue";
import ContractRemovingDialog from "@/components/for-my-contracts/ContractRemovingDialog.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";

@Component({
  components: {
    Page,
    XButton,
    WelcomeCard,
    ContractTable,
    XSlider,
    ContractAddingForm,
    ContractRemovingDialog,
  },
  computed: {
    ...mapGetters("contract", { useContract: "useContract" }),
    ...mapGetters("contracts", {
      sortedContractsByOrganization: "sortedContractsByOrganization",
      balanceListByContracts: "balanceList",
    }),
  },
})
class MyContracts extends Mixins(AppApiMixin) {
  showContractAddingForm = false;

  showContractRemovingDialog = false;
  contractIdForRemoving = -1;

  public mounted() {
    this.enterApp(true);
    //console.log(this.enterApp(true));
  }

  public closeContractRemovingDialog() {
    this.showContractRemovingDialog = false;
    this.contractIdForRemoving = -1;
  }
}

export default MyContracts;
