import {Module, Mutation, VuexModule} from "vuex-module-decorators";
import {ReportsDownloads} from "@/models/reports-downloads";

@Module({ namespaced: true })
class ReportsDownloadsModule extends VuexModule{
    asyncJobs: ReportsDownloads[] = []
    get getAsyncJobs(){
        return this.asyncJobs;
    }

    @Mutation
    setAsyncJobs(asyncJobs: ReportsDownloads[]){
        this.asyncJobs = asyncJobs
    }
}
export default ReportsDownloadsModule;